


































































































import { Component, Mixins } from 'vue-property-decorator';

import { ListingFilter, TestimonialFilter } from 'client-website-ts-library/filters';
import {
  ListingStatus,
  ListingCategory,
  Testimonial,
  WebsiteLevel,
} from 'client-website-ts-library/types';

import { API, Config } from 'client-website-ts-library/services';

import { View } from 'client-website-ts-library/plugins';

import TestimonialCards from '@/components/Testimonials/TestimonialCards.vue';
import Embeddable from '@/components/Common/Embeddable.vue';
import TestimonialCycler from '@/components/TestimonialCycler.vue';
import Listings from '../components/Listings.vue';
import ItemCycler from '../components/Cycler/ItemCycler.vue';
import WhatsYourHomeWorth from '../components/WhatsYourHomeWorth.vue';
import VideoBanner from '../components/VideoBanner.vue';
import { ItemCyclerSlide, ItemCyclerImageSlide } from '../app_code/ItemCycler';
import VideoTile from '../components/VideoTile.vue';

@Component({
  components: {
    Listings,
    ItemCycler,
    Embeddable,
    WhatsYourHomeWorth,
    VideoBanner,
    VideoTile,
    TestimonialCards,
  },
})
export default class Home extends Mixins(View) {
  private listingFilter = new ListingFilter({
    Statuses: [
      ListingStatus.Current,
      ListingStatus.UnderContract,
    ],
    Categories: [
      ListingCategory.ResidentialSale,
      ListingCategory.ResidentialLand,
      ListingCategory.Rural,
      ListingCategory.Commercial,
      ListingCategory.CommercialLand,
      ListingCategory.Business,
    ],
    PageSize: 6,
  });

  private cyclerImages: ItemCyclerSlide[] = [
    new ItemCyclerImageSlide({ source: '/assets/images/home/slider/1', formats: ['avif', 'webp', 'jpg'], fallback: 'jpg' }),
    new ItemCyclerImageSlide({ source: '/assets/images/home/slider/2', formats: ['avif', 'webp', 'jpg'], fallback: 'jpg' }),
    new ItemCyclerImageSlide({ source: '/assets/images/home/slider/3', formats: ['avif', 'webp', 'jpg'], fallback: 'jpg' }),
    new ItemCyclerImageSlide({ source: '/assets/images/home/slider/4', formats: ['avif', 'webp', 'jpg'], fallback: 'jpg' }),
    new ItemCyclerImageSlide({ source: '/assets/images/home/slider/5', formats: ['avif', 'webp', 'jpg'], fallback: 'jpg' }),
    new ItemCyclerImageSlide({ source: '/assets/images/home/slider/6', formats: ['avif', 'webp', 'jpg'], fallback: 'jpg' }),
  ];

  private testimonials: Testimonial[] | null = null;

  private testimonialCount = 0;

  private testimonialFilter: TestimonialFilter = new TestimonialFilter({
    SearchLevel: WebsiteLevel.Office,
    SearchGuid: Config.Website.Settings!.WebsiteId,
  });

  mounted() {
    let scr: HTMLScriptElement | null = document.getElementById('scrCoreLogicIngegration') as HTMLScriptElement | null;
    if (scr) scr.remove();

    setTimeout(() => {
      scr = document.createElement('script');
      scr.src = 'https://dpr.leadplus.com.au/main.js';
      scr.async = true;
      scr.defer = true;
      scr.id = 'scrCoreLogicIngegration';

      scr.onload = () => {
        console.log('Core Logic Integration Loaded');
      };

      document.body.append(scr);
    }, 500);
  }
}
