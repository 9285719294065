








import { Component, Vue, Prop } from 'vue-property-decorator';

import { FlexAlignment } from '../../app_code/FlexAlignment';

@Component
export default class SectionTitle extends Vue {
    @Prop()
    private readonly alignment!: FlexAlignment;

    @Prop({ default: 'var(--gutter) 0' })
    private readonly margin!: string;

    @Prop({ default: '2em' })
    private readonly fontSize!: string;
}
