


















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Modal extends Vue {
  @Prop({ default: '/assets/images/modal-default.jpg' })
  private readonly background!: string;
}
