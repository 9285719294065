
















import { IGeocoder, ServiceManager, ServiceType } from 'client-website-ts-library/services';
import { Coordinate } from 'client-website-ts-library/types';
import { GeoSearchFeature, GeoSearchResult } from 'client-website-ts-library/types/Geocoding/GeoSearchResult';
import { Component, Vue, Prop } from 'vue-property-decorator';
import Loader from './UI/Loader.vue';

@Component({
  components: {
    Loader,
  },
})
export default class AddressAutocomplete extends Vue {
  @Prop({ default: 'Search' })
  private readonly placeholder!: string;

  @Prop({ default: undefined })
  private readonly affinity?: Coordinate;

  private geocoder: IGeocoder = ServiceManager.Require<IGeocoder>(ServiceType.Geocoder);

  private autocompleteTimeout?: number;

  private searching = false;

  private search = '';

  private results: GeoSearchResult | null = null;

  private focused = false;

  handleBlur() {
    setTimeout(() => {
      this.focused = false;
    }, 100);
  }

  selectResult(item: GeoSearchFeature) {
    this.search = item.Name;

    this.focused = false;

    this.$emit('selected', item);
  }

  autocomplete() {
    window.clearTimeout(this.autocompleteTimeout);

    this.autocompleteTimeout = setTimeout(() => {
      if (!this.search.length || this.searching) return;

      const searchParts = this.search.split('/');

      const searchAddress = searchParts.length === 1 ? searchParts[0] : searchParts[1];

      this.searching = true;
      this.geocoder.Search(searchAddress, this.affinity).then((results) => {
        console.log(results);
        this.searching = false;

        this.results = results;

        if (searchParts.length > 1) {
          this.results.Results = this.results.Results.map((res) => {
            // eslint-disable-next-line no-param-reassign
            res.Name = `${searchParts[0]}/${res.Name}`;

            return res;
          });
        }
      });
    }, 1000);
  }
}
